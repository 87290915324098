<template>
	<div>
		<div class="content">
			<!-- 麵包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb>
					<el-breadcrumb-item :to="{ path: '/diagnosis' }">设备租赁</el-breadcrumb-item>
					<el-breadcrumb-item>商品列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="category">
				<div class=" flex ai-center category1" v-if="showCategory1">
					<div class="category1-left">分类：</div>
					<div class="flex ai-center categoryItem">
						<div v-for="(item, i) in category1" :class="category1Id == item.id ? 'categoryItem-item' : ''" :key="'cate' + i" @click="setCategory1(item.id)">{{ item.name }}</div>
					</div>
				</div>
				<div class=" flex ai-center category1" v-if="category2.length>0">
					<div class="category1-left">分类：</div>
					<div class="flex ai-center categoryItem">
						<div v-for="(item, i) in category2" :class="category2Id == item.id ? 'categoryItem-item' : ''" :key="'cate' + i" @click="setCategory2(item.id)">{{ item.name }}</div>
					</div>
				</div>
				<div class=" flex ai-center brand" >
					<div class="category1-left">品牌：</div>
					<div class="flex ai-center categoryItem">
						<div v-for="(item, i) in brand" :class="brandId == item.id ? 'categoryItem-item' : ''" :key="'cate' + i" @click="setBrand(item.id)">{{ item.name }}</div>
					</div>
				</div>
			</div>
			<!-- 排序 -->
			<div class="sort flex ai-center">
				<div class="btn cursor" :class="{ active: currentIndex == index }" v-for="(item, index) in sortList" :key="index" @click="chooseSort(index)">
					<div>{{ item.value }}</div>
					<div v-if="item.type == 1" class="flex flex-col sort-item">
						<i class="el-icon-caret-top sort-item-i1" :class="!item.up?'color0':''"></i>
						<i class="el-icon-caret-bottom sort-item-i2" :class="item.up?'color0':''"></i>
					</div>
				</div>
			</div>
			<div v-if="expertList && expertList.length">
				<!-- 列表 -->
				<div class="recommended">
					<div class="recomoutside cursorPointer" v-for="(item, index) in expertList" :key="index">
						<div class="recomBlock" @click="toexpertDetail(item.id)">
							<div class="img"><img :src="photoURL + `${item.rentImg}`" alt="" /></div>
							<div style="margin: 0 10px;">
								<div class="flex align-center jc-between" style="width: 100%;">
									<span class="Recomword2">{{ item.brandName }}</span>
									<span class="Recomword3">{{ item.priceMin ? '¥ ' + item.priceMin : '洽谈' }}</span>
								</div>
								<div class="Recomword4">{{ item.prodDesc }}</div>
								<div class="flex align-center jc-between" style="width: 100%;margin-bottom: 10px;">
									<div class="Recomword1">{{ item.name }}</div>
									<div style="line-height:30px;color:#888888 ;">规格种类：{{ item.skuNum }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 分页 hide-on-single-page-->
				<div class="globalpPagination">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="current"
						:page-size="size"
						layout=" prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<div class="empty flex jc-center ai-center" v-else>
				<div class="flex flex-col jc-center ai-center">
					<img src="../img/expertEmpty.png" />
					<span>暂无 <span style="color: red;font-size: 18px;">{{keyword}}</span> 相关内容</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Bus from '@/bus/index.js';
export default {
	data() {
		return {
			sortList: [
				{ value: '默认', index: 0 , type: 0}, 
				{ value: '价格排序', index: 1, type: 1 ,up:true}, 
				// { value: '销量排序', index: 2, type: 1 ,up:true},
			],
			cityIndex: 0,
			keyword: '',
			cityList: [],
			priceSort: 0,
			expertList: [],
			current: 1,
			total: 0,
			size: 16,
			cityVal: '',
			currentIndex: 0,
			category1: [],
			category1Id: null,
			category2:[],
			category2Id: null,
			brand:[],
			brandId:null,
			showCategory1:true
		};
	},
	methods: {
		chooseSort(val) {
			if (this.currentIndex != val) {
				this.currentIndex = val;
				switch (val) {
					case 0:
						this.priceSort =0;
						this.sellNumSort =0;
						this.getList();
						break;
					case 1:
						//1-升序 2-降序
						this.priceSort = 1;
						this.getList();
						break;
					case 2:
						//1-升序 2-降序
						this.sellNumSort = 1;
						this.getList();
						break;
				}
			} else {
				switch (val) {
					case 1:
						if(this.sortList[val].up){
							this.priceSort = 2;
						}else{
							this.priceSort = 1;
						}
						this.sortList[val].up = !this.sortList[val].up
						this.getList();
						break;
					case 2:
						if(this.sortList[val].up){
							this.sellNumSort = 2;
						}else{
							this.sellNumSort = 1;
						}
						this.sortList[val].up = !this.sortList[val].up
						this.getList();
						break;
				}
			}
		},
		// 获取商品列表
		getList() {
			let param = {
				// city: this.cityVal,
				priceSort: this.priceSort,
				current: this.current,
				size: this.size,
				keyword: this.keyword,
				category1Id: this.category1Id,
				category2Id: this.category2Id,
				brandId: this.brandId,
				sellNumSort:this.sellNumSort
			}
			this.expertList = []
			this.$get('/shop-api/mall/rent/list', param).then(data => {
				if (data.code != 1000) {
					return;
				}
				this.expertList = data.data.records;
				this.total = data.data.total;
			});
		},
		//获取一级类目
		getCategory() {
			this.$get('/shop-api/mall/rent/category').then(data => {
				if (data.code != 1000) {
					return;
				}
				this.category1 = data.data;
			});
		},
		//获取二级类目
		getCategory2(param,type) {
			this.$get('/shop-api/mall/rent/search/category',param).then(data => {
				if (data.code != 1000) {
					return;
				}
				if(type==0){
					this.category2 = data.data[0].child;	
				}
			});
		},
		toexpertDetail(id) {
			this.$router.push({
				path: '/leaseDetails',
				query: { id, num: '1' }
			});
		},
		// 每页显示的条数
		handleSizeChange(val) {
			this.size = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.current = val;
			this.getList();
		},
		//选择一级分类
		setCategory1(id){
			if(id==this.category1Id){
				this.category1Id= null;
				this.category2Id= null;
				this.category2 = [];
				this.getList();
				return
			}
			this.category1Id = id;
			let obj = {
				category1Id:id
			}
			this.category2Id= null;
			this.getCategory2(obj,0)
		},
		//选择二级分类
		setCategory2(id){
			if(id==this.category2Id){
				this.category2Id= null;
				this.getList();
				return
			}
			this.category2Id = id;
			this.getList();
		},
			//选择品牌
		setBrand(id){
			if(id==this.brandId){
				this.brandId= null;
				this.getList();
				return
			}
			this.brandId = id;
			this.getList();
		},
		//获取品牌
		getBrand(param,type) {
			this.$get('/shop-api/mall/rent/search/brand',param).then(data => {
				if (data.code != 1000) {
					return;
				}
				if(type==0){
					this.brand = data.data;	
				}
			});
		}
	},
	watch: {
		'$route.query.id'(newVal) {
		},
		$route: {
			handler(val) {
				if (val.name == 'leaseList'){
					if(val.query.search){
						this.keyword = val.query.search;
					}
					if(val.query.id){
						this.showCategory1 = false;
						this.category1Id = val.query.id;
						let obj = {
							category1Id:val.query.id
						}
						this.category2Id= null;
						this.getCategory2(obj,0)
					}
				} 
				this.getList();
			},
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			Bus.$on('headerSearch', keyword => {
				this.keyword = keyword;
				console.log(this.keyword)
				this.getList(keyword);
			});
		});
		this.getList();
		this.getCategory();
		this.getBrand({},0);
	},
	beforeDestroy() {
		Bus.$off('headerSearch');
	}
};
</script>

<style lang="scss" scoped>
* {
	letter-spacing: 1px !important;
	.content {
		.el-button {
			height: 40px;
		}
		background-color: #f5f5f5;
		width: 1440px;
		margin: 0 auto;
		.breadcrumb {
			height: 40px;
			color: #8c8c8c;
			letter-spacing: 1px;
			padding-left: 10px;
			background-color: #efeff4;
			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}
		.sort {
			width: 1420px;
			margin: 0 auto;
			border-radius: 5px;
			margin-top: 15px;
			padding-left: 5px;
			background-color: #ffffff;
			height: 64px;
			padding: 10px;
			.btn {
				font-size: 14px;
				font-weight: 500;
				line-height: 30px;
				text-align: center;
				height: 30px;
				padding:0 10px;
				margin-right: 10px;
				background: #e5e5e5;
				border-radius: 4px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.sort-item {
					padding-left: 5px;
					i {
						padding: 0;
					}
					.color0 {
						color: #262626;
					}
					.sort-item-i1 {
						margin-bottom: -3px;
					}
					.sort-item-i2 {
						margin-top: -3px;
					}
				}
			}
			.active {
				color: white;
				background-color: #4a99f6;
			}
			.active1 {
				// cursor: pointer;
				.el-dropdown {
					color: #fff;
					background: #4a99f6;
				}
			}
		}
		.recommended {
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			.recomoutside {
				display: flex;
				justify-content: center;
				align-items: center;
				.recomBlock {
					border-radius: 5px;
					background-color: white;
					width: 340px;
					margin: 10px;
					.img {
						width: 300px;
						height: 300px;
						margin: 10px auto;
						img {
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}
					.Recomword1 {
						width: 240px;
						color: #333;
						font-size: 18px;
						line-height: 30px;
						font-weight: 600;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; // 默认不换行；
						text-align: left;
					}
					.Recomword2 {
						color: #888888;
						font-size: 14px;
						line-height: 30px;
						width: 100px;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; // 默认不换行；
						text-align: left;
					}
					.Recomword3 {
						font-size: 20px;
						font-weight: 600;
						color: #4a99f6;
					}
					.Recomword4 {
						width: 300px;
						font-size: 14px;
						line-height: 20px;
						color: #888888;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; // 默认不换行；
					}
					&:hover {
						box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
					}
				}
			}
		}
		.empty {
			color: #8c8c8c;
			min-height: 700px;
		}
	}
}
.el-dropdown {
	font-size: 16px;
	font-weight: 500;
	line-height: 40px;
	color: #262626;
	text-align: center;
	width: 100px;
	height: 40px;
	margin-right: 10px;
	background: #e5e5e5;
	border-radius: 4px;
}
.el-rate__icon {
	font-size: 20px;
}
.category {
	width: 1420px;
	margin: 0 auto;
	border-radius: 5px;
	margin-top: 15px;
	background-color: #ffffff;
	padding: 10px;
	font-size: 14px;
	.category1 {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ebebeb;
		.category1-left {
			color: #8c8c8c;
		}
	}
	.brand{
		padding-bottom: 10px;
		.category1-left {
			color: #8c8c8c;
		}
	}
	.categoryItem {
		.categoryItem-item {
			color: #ffffff;
			background-color: #4a99f6;
		}
		div {
			margin: 0 5px;
			cursor: pointer;
			color: #262626;
			padding: 5px 10px;
			border: 1px solid rgba(0, 0, 0, 0);
			border-radius: 20px;
		}
		:hover {
			border: 1px solid #4a99f6;
		}
	}
}
</style>
